import {
  Box,
  Divider,
  HStack,
  IconButton,
  Link,
  Text,
  VStack,
  useColorModeValue,
} from "@chakra-ui/react";
import { FaEnvelope, FaGithub, FaLinkedin } from "react-icons/fa";

export const Footer = () => {
  const textColor = useColorModeValue("gray.800", "gray.100");

  return (
    <Box bottom={"1rem"} position={"absolute"}>
      <VStack spacing={0} alignItems={"center"}>
        <Text
          fontSize={{ base: "xs", md: "md", lg: "xl" }}
          color={textColor}
          fontWeight="bold"
          bgGradient="linear(to-l, teal.400,green.600)"
          bgClip="text"
        >
          Cristian Henz Krein
        </Text>
        <Text
          fontSize={{ base: "xx-small", md: "x-small", lg: "small" }}
          color={textColor}
        >
          Software Developer
        </Text>
        <Divider
          style={{
            height: "2px",
            background:
              "linear-gradient(to right, transparent, black, transparent)",
            margin: "5px 0",
          }}
        />

        <HStack spacing={2} alignItems={"center"}>
          <Link
            href="https://br.linkedin.com/in/cristian-henz-krein-9a0a45190"
            isExternal
          >
            <IconButton
              as={FaLinkedin}
              aria-label="LinkedIn"
              size="xs"
              colorScheme="teal"
              variant="ghost"
              _hover={{ color: "teal.500" }}
              transition="0.3s ease-in-out"
            />
          </Link>

          <Link href="https://github.com/cristiankrein" isExternal>
            <IconButton
              as={FaGithub}
              aria-label="GitHub"
              size="xs"
              colorScheme="teal"
              variant="ghost"
              _hover={{ color: "teal.500" }}
              transition="0.3s ease-in-out"
            />
          </Link>
          <Link href="mailto:cristian.krein@outlook.com">
            <IconButton
              as={FaEnvelope}
              aria-label="Email"
              size="xs"
              colorScheme="teal"
              variant="ghost"
              _hover={{ color: "teal.500" }}
              transition="0.3s ease-in-out"
            />
          </Link>
        </HStack>
      </VStack>
    </Box>
  );
};
