import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  Text,
  VStack,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
import { createStaticPix, hasError } from "pix-utils";
import { QRCodeSVG } from "qrcode.react";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  FaCopy,
  FaKey,
  FaMoneyBillWave,
  FaMoon,
  FaRegCommentDots,
  FaShare,
  FaSun,
  FaUser,
} from "react-icons/fa";
import { Footer } from "../../../components/Footer";
import { maskCurrency, unMaskCurrency } from "../../utils/currency";

interface QRGeneratorProps {
  pixKey: string;
  merchantName: string;
  merchantCity: string;
  transactionAmount: string;
  infoAdicional: string;
}

export const QRGenerator = () => {
  const { colorMode, toggleColorMode } = useColorMode();
  const bgColor = useColorModeValue("gray.100", "#282c34");
  const textColor = useColorModeValue("gray.800", "gray.100");
  const { handleSubmit, control, setValue, getValues } =
    useForm<QRGeneratorProps>({
      defaultValues: {
        pixKey: "",
        merchantName: "",
        transactionAmount: "",
        infoAdicional: "",
      },
    });

  const [qrValue, setQrValue] = useState("");
  const [showInput, setShowInput] = useState(true);

  const onSubmit = (value: QRGeneratorProps) => {
    const amount = unMaskCurrency(value.transactionAmount);
    const pix = createStaticPix({
      pixKey: value.pixKey,
      merchantName: value.merchantName,
      merchantCity: "SAO PAULO",
      transactionAmount: amount,
      infoAdicional: value.infoAdicional,
    });

    if (!hasError(pix)) {
      const brCode = pix.toBRCode();
      navigator.clipboard.writeText(brCode);
      setQrValue(brCode);
      setShowInput(false);
    }
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(qrValue);
  };

  const handleReset = () => {
    setValue("pixKey", "");
    setValue("merchantName", "");
    setValue("transactionAmount", "");
    setValue("infoAdicional", "");
    setQrValue("");
    setShowInput(true);
  };

  const handleShare = () => {};

  return (
    <Box
      bg={bgColor}
      color={textColor}
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      transition="0.3s ease-in-out"
    >
      <Flex
        direction="row"
        justifyContent="center"
        alignItems="center"
        width="100%"
        maxW="1200px"
        p={8}
        wrap="wrap"
      >
        <IconButton
          position="absolute"
          top="1rem"
          right="1rem"
          aria-label="Switch dark/light mode"
          icon={colorMode === "dark" ? <FaSun /> : <FaMoon />}
          onClick={toggleColorMode}
        />

        {showInput && (
          <Stack
            spacing={4}
            width={{ base: "100%", md: "50%" }}
          >
            <Text
              textAlign={"center"}
              fontSize={{ base: "2xl", md: "3xl", lg: "5xl" }}
              color={textColor}
              fontWeight="bold"
              bgGradient="linear(to-l, teal.400,green.600)"
              bgClip="text"
            >
              Gerador de QR Code Pix
            </Text>
            <Text
              textAlign={"center"}
              fontSize={{ base: "xl", md: "1xl", lg: "2xl" }}
              color={textColor}
            >
              Insira os dados do Pix
            </Text>
            <Controller
              name="pixKey"
              rules={{ required: "Campo obrigatório" }}
              control={control}
              render={({ field, formState: { errors } }) => (
                <FormControl isRequired isInvalid={!!errors.pixKey}>
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents="none"
                      children={<Icon as={FaKey} />}
                    />
                    <Input
                      {...field}
                      placeholder="Chave Pix"
                      _focus={{
                        borderColor: "teal.500",
                      }}
                    />
                  </InputGroup>
                  <FormErrorMessage>
                    {errors.pixKey && errors.pixKey.message}
                  </FormErrorMessage>
                </FormControl>
              )}
            />
            <Controller
              name="merchantName"
              rules={{ required: "Campo obrigatório" }}
              control={control}
              render={({ field, formState: { errors } }) => (
                <FormControl isRequired isInvalid={!!errors.pixKey}>
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents="none"
                      children={<Icon as={FaUser} />}
                    />
                    <Input
                      {...field}
                      placeholder="Nome"
                      _focus={{
                        borderColor: "teal.500",
                      }}
                    />
                  </InputGroup>
                  <FormErrorMessage>
                    {errors.pixKey && errors.pixKey.message}
                  </FormErrorMessage>
                </FormControl>
              )}
            />
            <Controller
              name="transactionAmount"
              rules={{ required: "Campo obrigatório" }}
              control={control}
              render={({ field, formState: { errors } }) => (
                <FormControl isRequired isInvalid={!!errors.pixKey}>
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents="none"
                      children={<Icon as={FaMoneyBillWave} />}
                    />
                    <Input
                      {...field}
                      placeholder="Valor da Transação"
                      _focus={{
                        borderColor: "teal.500",
                      }}
                      type="text"
                      onChange={(event) => {
                        const value = event.target.value;
                        const formattedValue = maskCurrency(
                          Number(value.replace(/\D/g, "")) / 100
                        );
                        field.onChange(formattedValue);
                      }}
                    />
                  </InputGroup>
                  <FormErrorMessage>
                    {errors.pixKey && errors.pixKey.message}
                  </FormErrorMessage>
                </FormControl>
              )}
            />
            <Controller
              name="infoAdicional"
              rules={{ required: "Campo obrigatório" }}
              control={control}
              render={({ field, formState: { errors } }) => (
                <FormControl isRequired isInvalid={!!errors.pixKey}>
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents="none"
                      children={<Icon as={FaRegCommentDots} />}
                    />
                    <Input
                      {...field}
                      placeholder="Informação Adicional"
                      _focus={{
                        borderColor: "teal.500",
                      }}
                    />
                  </InputGroup>
                  <FormErrorMessage>
                    {errors.pixKey && errors.pixKey.message}
                  </FormErrorMessage>
                </FormControl>
              )}
            />
            <Button
              colorScheme="teal"
              _hover={{ bgColor: "teal.500" }}
              onClick={handleSubmit(onSubmit)}
            >
              Gerar QRCode
            </Button>
          </Stack>
        )}

        {!showInput && (
          <Flex
            direction="column"
            justifyContent="center"
            alignItems="center"
            width={{ base: "100%", md: "50%" }}
            height={{ base: "auto", md: "200px" }}
          >
            <VStack spacing={4}>
              <Text
                textAlign={"center"}
                fontSize={{ base: "2xl", md: "3xl", lg: "5xl" }}
                color={textColor}
                fontWeight="bold"
                bgGradient="linear(to-l, teal.400,green.600)"
                bgClip="text"
              >
                QR Code Pix
              </Text>
              <Text
                textAlign={"center"}
                fontSize={{ base: "xl", md: "1xl", lg: "2xl" }}
                color={textColor}
              >
                {getValues("transactionAmount")}
              </Text>
              <Stack
                p={5}
                bg={"white"}
                borderColor={textColor}
                borderWidth={2}
                rounded={5}
              >
                <QRCodeSVG size={200} value={qrValue} />
              </Stack>

              <Flex direction="row">
                <HStack spacing={2} alignItems={"center"}>
                  <IconButton
                    aria-label="Copiar QRCode"
                    _hover={{ bgColor: "teal.400", color: "white" }}
                    icon={<FaCopy />}
                    onClick={handleCopy}
                  />
                  <IconButton
                    aria-label="Compartilhar QRCode"
                    _hover={{ bgColor: "teal.400", color: "white" }}
                    icon={<FaShare />}
                    onClick={handleShare}
                  />
                </HStack>
              </Flex>
              <Button
                colorScheme="teal"
                _hover={{ bgColor: "teal.500" }}
                onClick={handleReset}
              >
                Gerar outro QRCode
              </Button>
            </VStack>
          </Flex>
        )}

        <Footer />
      </Flex>
    </Box>
  );
};
